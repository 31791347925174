import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Imprint" />
    <div style={{textAlign: 'center', width: '100%', margin: 'auto'}}>
      <div>
        Fluctua Records GmbH
      </div>
      <div>
        Amtsgericht Charlottenburg | HRB 210945B
      </div>
      <div>
        Geschäftsführer: Denís Graña Fernández
      </div>
    </div>
    
  </Layout>
)

export default IndexPage
